import React, { useEffect, useState } from "react";
import {LOGIN_API,GET_COMPANY_DETAILES,COMPANY_TYPE,BUILD_POS_TYPE} from '../../constant';
import { useHistory } from 'react-router-dom';
// styles
import useStyles from "./styles";
// logoS
import POS_logo from "../../images/posLOGO.png";
import Defaultogo from "../../images/saitec_logo.svg";
import RESTRO_logo from "../../images/restaurantLOGO.png";
import order_mngment_LOGO from "../../images/indosupLOGO.svg";
import axios from "axios";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
export default function Login(props) {
  const classes = useStyles();
  const history = useHistory();
  
  // global
  // const userDispatch = useUserDispatch();
  
  // local
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeTabId, setActiveTabId] = useState(0);
  const [iscompanyFound, setIscompanyFound] = useState(false);
  // const [cmpnyNo, setCmpnyNo] = useState(history.location.state.cmpnyNo);
const [showErrorAlert, setShowErrorAlert] = useState();
const [showErrorAlertHeading, setShowErrorAlertHeading] = useState("");
const [logo, setLogo] = useState("");
const [companyType, setCompanyType] = useState();
const [companyName, setCompanyName] = useState();
const [open, setOpen] = useState(false);
const [loginLogo,setloginLogo] = useState(Defaultogo);
const [cmpnyTxt,setCmpyTxt] = useState("Saitec's ERP system can help improve your organization's efficiency and data accuracy by putting all your data in one place. The system can also enable better collaboration and make your work environment more data-driven.");
  const [loginFormVal, setLoginFormVal] = useState({
    "userName":"",
    "password":"",
    "CompanyNumber":0,

});
const [CmpntDetailsFormVal, setCmpntDetailsFormVal] = useState({
  "CompanyNumber":"",
  "companyPhone":"",
  "Type":BUILD_POS_TYPE
});
useEffect(() => {
localStorage.removeItem('cmpnyType')
history.push('/login')
}, []);

const NumaricalCheck=(e)=>
{
    var keyCode = e.charCode;
    if (keyCode < 48 || keyCode > 57) {
        e.preventDefault(); 
    }
}

const handleChangeCompany=(e)=>
{
  setIsLoading(true)
  setCmpntDetailsFormVal({...CmpntDetailsFormVal,[e.target.name]:e.target.value});
  if(e.target.name=="CompanyNumber")
  {
    setLoginFormVal({...loginFormVal,"CompanyNumber":e.target.value})
  }
  setIsLoading(false)
}
const handleChange=(e)=>
{
  setLoginFormVal({...loginFormVal,[e.target.name]:e.target.value})
}//for company details btn form
const submitCompanyform =()=>{
  setOpen(false);
  if(CmpntDetailsFormVal.CompanyNumber===0||CmpntDetailsFormVal.CompanyNumber===""||CmpntDetailsFormVal.companyPhone =="")
  {
  setShowErrorAlertHeading("Blank Fields");
   setShowErrorAlert("Please Fill Mandetry Fields.");
   setOpen(true);

   return;
  } 

  setIsLoading(true);
  debugger;
     axios.post(GET_COMPANY_DETAILES,CmpntDetailsFormVal,{withCredentials: true}, {credentials: 'include'},
     ).then((response)=>{
      setIsLoading(false);
       if(response.data.IsSuccess === true){
        setCompanyType(response.data.Data.Type);
        setCompanyName(response.data.Data.CompanyName);
          setIscompanyFound(true);
          localStorage.setItem("cmpnyNo", response.data.Data.CompanyNumber);
          localStorage.setItem("cmpnyType", response.data.Data.Type);
          localStorage.setItem("username", response.data.Data.CompanyName);
          var splittedComapnyName = response.data.Data.Type.split(',')[0].toUpperCase()
          if(splittedComapnyName===COMPANY_TYPE.POS)
          {
            setLogo(splittedComapnyName);
            setloginLogo(POS_logo);
            setCmpyTxt("The all-in-one POS System from Saitec is a great way to streamline your customer transactions, payments, and inventory levels. It also helps you manage your staff and provides you with live data access so you can make informed decisions about your business.");
          }
          else if(splittedComapnyName===COMPANY_TYPE.RESTAURANT)
          {
            setLogo(response.data.Data.Type);
            var favicon = document.querySelector("link[rel~='icon']");
            favicon.href = RESTRO_logo;
            setloginLogo(RESTRO_logo);
            setCmpyTxt("Saitec offers powerful restaurant management software, providing comprehensive solutions to streamline operations, enhance efficiency, and maximize profitability for food service businesses.");

          }
          else if(splittedComapnyName===COMPANY_TYPE.ORDER_MANAGEMENT)
          {
            setLogo(response.data.Data.Type);
            var favicon = document.querySelector("link[rel~='icon']");
            favicon.href = order_mngment_LOGO;
            setloginLogo(order_mngment_LOGO);
          setCmpyTxt("Saitec's ERP system can help improve your organization's efficiency and data accuracy by putting all your data in one place. The system can also enable better collaboration and make your work environment more data-driven.");

          }
          else{
           setCmpyTxt("Saitec's ERP system can help improve your organization's efficiency and data accuracy by putting all your data in one place. The system can also enable better collaboration and make your work environment more data-driven.");
          }
       }
       else{
        setShowErrorAlertHeading("Invalid Data");
        setShowErrorAlert(response.data.Message);
        setOpen(true);
       }
     })
 
   }
//for login btn click
  const submitloginform =()=>{
    setOpen(false);
 if(loginFormVal.userName===""||loginFormVal.password===""||loginFormVal.CompanyNumber==="")
 {
  setShowErrorAlertHeading("Blank Fields");
  setShowErrorAlert("Fields cannot Blank!");
  setOpen(true);
  return;
 }
 setIsLoading(true)
    axios.post(LOGIN_API,loginFormVal,{withCredentials: true}, {credentials: 'include'},
    ).then((response)=>{
      setIsLoading(false);
      if(response.data.IsSuccess === true){
        debugger;
        localStorage.setItem("Financial_Year", response.data.Data.fyear);
          // history.push("/dashboard");
          window.location.href="/dashboard";
      }
      else{
        setShowErrorAlertHeading("Invalid Credentials");
        setShowErrorAlert("username or password is incorrect !");
        setOpen(true);
      }
    })
  
  }

  const chechEmailValidation=(e)=>
    {
        var keyCode = e.charCode;
  
        if ((keyCode >= 65 && keyCode <= 90) || (keyCode >= 97 && keyCode <= 122) || (keyCode === 64) || (keyCode >= 48 && keyCode <= 57)|| (keyCode === 46)) {
        }
        else
        {
            e.preventDefault(); 
        }
    }
    const chechForBlankSpace=(e)=>
    {
        var keyCode = e.charCode;
  
        if (keyCode !== 32) {
        }
        else
        {
            e.preventDefault(); 
        }
    }
    //---------reset company-----------
const rstCmpyHndl=()=>
{
  setIscompanyFound(false);
   setOpen(false);
   setloginLogo(Defaultogo);
   setCmpyTxt("Saitec's ERP system can help improve your organization's efficiency and data accuracy by putting all your data in one place. The system can also enable better collaboration and make your work environment more data-driven.");
}
  return (
  <>
{
            isLoading?  <div className='Commonloader' style={{left:0,top:0}}>
            </div>:null
          }
<div>
      <video autoPlay muted loop style={{ width: '100%' }} className='loginbgimg'>
       <source src="https://prod-streaming-video-msn-com.akamaized.net/278b1c4f-3545-422c-9d56-242f18adadc9/2e3d6097-b233-40af-88f4-a90a74a33fa0.mp4" type="video/mp4" />
    </video>
                {/* <img src={webbg}  alt="showcraftbackground Images" className='loginbgimg' style={{ width: '100%',height:'100%' }} /> */}
                <div className="content">
                {open? <Collapse in={open}>
            <Alert 
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
              severity="error"
            >
               <AlertTitle>{showErrorAlertHeading}</AlertTitle>
            <strong>{showErrorAlert}</strong>
            </Alert>
          </Collapse>:null}
                    <div className='row'>
                        <div className='col-md-6 col-12 col-sm-12 loginpageLeft pt-4' >
                            <div className='logo loginSiseBox'>
                              {/* {
                                iscompanyFound?
                                <img className="w-25" src={loginLogo} />
                                : */}
                                <img className="w-25" src={loginLogo} />
                                  {/* } */} 
                                <p className="pt-5 h4">Get Ahead of Your Competition with SAITEC International</p>
                                {/* {
                                iscompanyFound?
                                <p className="h6 mt-3 loginSiseBoxTxt" style={{textAlign:"justify"}}>{cmpnyTxt}</p>
                                : */}
                                <p className="h6 mt-3 loginSiseBoxTxt" style={{textAlign:"justify"}}>{cmpnyTxt}</p>
                                  {/* } */}  
                                </div>
                        </div>
                        <div className='col-md-5 col-12 col-sm-12 text-center'>
                            <div className='loginformDiv py-4'>
                                {
                                  iscompanyFound?
                                  <>
                                <div className="my-2">
                                <h3>Welcome <span className={`text-lowercase fst-italic ${classes.primaryColor}`}>{companyName}</span></h3>
                                  </div> 
                                  <p className='login_text' >Login for company <span className={classes.primaryColor}>{CmpntDetailsFormVal.CompanyNumber}</span></p>
                                  <div className="my-3">
                                  <label for="userName" class="form-label">User Name:<span className="text-danger">*</span></label>
                                  <input
                                    id="uEmail"
                                    name="userName"
                                    placeholder="Enter your Username..."
                                    type="email"
                                    value={loginFormVal.userName}
                                     onChange={e=>handleChange(e)}
                                    onKeyPress={chechEmailValidation}
                                  /></div>
                                  <div className="my-3">
                                  <label for="password" class="form-label">password: <span className="text-danger">*</span></label>
                                  <input
                                    id="pass"
                                    name="password"
                                    placeholder="Enter your Password..."
                                    type="password"
                                    value={loginFormVal.password}
                                    onChange={e=>handleChange(e)}
                                    onKeyPress={chechForBlankSpace}
                                    style={{fontWeight:"600",fontSize:"14px",width:"270px"}}
                                  />
                                  <div className='login-ask'>
                                      <div className="text-center">
                                      <p onClick={e=>{rstCmpyHndl()}}>Reset Company</p>
                                      </div>
                                      <div>
                                          {/* <p >Forget password ?</p> */}
                                      </div>
                                      </div>
  
                                  </div>
                                 <div className="my-3">
                                 <button type="submit" className={`loginBtn buttn btn ${classes.formButtons}`} value="Submit" onClick={submitloginform}>
                                  Submit</button>
                                  </div>
                                  
                                  </>:  <>
                                <p className='login_text' >Company Details</p>
                                <div className="my-3">
                                <label for="CompanyNumber" class="form-label">Company Number:<span className="text-danger">*</span></label> 
                                <input
                                  type="text"
                                  id="CompanyNumber" name="CompanyNumber" 
                                  placeholder="Enter Company Number Here..." 
                                  value={CmpntDetailsFormVal.CompanyNumber}
                                  onKeyPress={NumaricalCheck}
                                   onChange={e=>handleChangeCompany(e)}
                                />
                                 </div>
                                 
                                <div className="my-3">
                                <label for="companyPhone" class="form-label">Company Phone : <span className="text-danger">*</span></label>
                                <input
                                  type="text"
                                  id="companyPhone" name="companyPhone" 
                                  placeholder="Enter Company Mobile Number Here..." 
                                  value={CmpntDetailsFormVal.companyPhone}
                                  maxLength="10"
                                  onKeyPress={NumaricalCheck}
                                   onChange={e=>handleChangeCompany(e)}
                                />  </div>
                                 <div className="my-3">
              <button type="submit" className={`loginBtn buttn btn ${classes.formButtons}`}value="Submit" onClick={submitCompanyform}  >
              Submit</button>
              </div>
                                </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
  </>
  );
}



{/* <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    disabled={
                      loginValue.length === 0 || passwordValue.length === 0
                    }
                    onClick={() =>
                      loginUser(
                        userDispatch,
                        loginValue,
                        passwordValue,
                        props.history,
                        setIsLoading,
                        setError,
                      )
                    }
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Login
                  </Button>
                )}
                
              </div> */}


