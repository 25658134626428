import React, { useState,useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import useStyles from "./styles";
import axios from "axios";
import {GET_ALL_CITY,COMPANY_CODE, GET_ALL_STATE,CREATE_NEW_CLIENT,}from "../../constant";




const FormComponent = () => {
  const classes = useStyles();

  const [formData, setFormData] = useState({
    CompanyNumber: COMPANY_CODE,
    fullName: '',
    mobileNumber: '',
    email: '',
    dateOfBirth: '',
    gstNumber: '',
    address: '',
    selectedCountry: '',
    selectedState: '',
    selectedCity: ''
  });

  const handleChange =  (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const [isSuccess, setSuccess] = useState(false);
  const handleSubmit = async (e) => {

    e.preventDefault();
    console.log(formData)

    try {
      const response = await axios.post( CREATE_NEW_CLIENT, formData);
      console.log(response.data);
      console.log(response.IsSuccess);
      if (response.data.IsSuccess === true) {

      }
      else {

      }

      setFormData({
        CompanyNumber: COMPANY_CODE,
        fullName: '',
        mobileNumber: '',
        email: '',
        dateOfBirth: '',
        gstNumber: '',
        address: '',
        selectedCountry: '',
        selectedState: '',
        selectedCity: '',
      });
    } catch (error) {
      console.error('Error creating client:', error);
    }    
  };

  const [companyColor, setcompanyColor] = useState("")
  const [cities, setCities] = useState([]);
  const fetchCities = async () => {
    console.log(COMPANY_CODE);
    console.log(GET_ALL_CITY);
    try {
      const response = await axios.post(GET_ALL_CITY, {
        CompanyNumber: COMPANY_CODE,
        state: 'DELHI'
      });
      const cityList = response.data.Data;
      setCities(cityList);
    } catch (error) {
      console.error('Error fetching cities:', error);
    
    }
  };
  useEffect(() => {
    fetchCities();
  }, []);
  const [states, setstates] = useState([]);

  const fetchStates = async () => {
    
    console.log(COMPANY_CODE);
    console.log(GET_ALL_STATE);
    try {
      const response = await axios.post(GET_ALL_STATE, {
        CompanyNumber: COMPANY_CODE,
      });
      const stateList = response.data.Data; 
      setstates(stateList);
    } catch (error) {
      console.error('Error fetching states:', error);
    
    }
  };
  useEffect(() => {
    fetchStates();
  }, []);
  


  return (
    <div className={classes.formContainer}>
      <h3>Create New Client</h3>
      <form onSubmit={handleSubmit}>
        <div className={classes.formGroup}>
          <label htmlFor="fullName" className={classes.label}>Full Name</label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            className={classes.input}
            placeholder="Enter Your  Name"
          />
        </div>

        <div className={classes.formGroup}>
          <label htmlFor="mobileNumber" className={classes.label}>Mobile Number</label>
          <select
              id="countryCode"
              name="countryCode"
              value={formData.countryCode}
              onChange={handleChange}
              className={classes.select}
              style={{width:"70px"}}
            >
              <option value="+91">+91</option>
            </select>
          <input
            type="text"
            id="mobileNumber"
            name="mobileNumber"
            value={formData.mobileNumber}
            onChange={handleChange}
            className={classes.input}
            placeholder="Enter Mobile Number"
            style={{width:"80%"}}
          />
        </div>

        <div className={classes.formGroup}>
          <label htmlFor="email" className={classes.label}>Email ID</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className={classes.input}
            placeholder="Enter Email "
          />
        </div>

        <div className={classes.formGroup}>
          <label htmlFor="dateOfBirth" className={classes.label}>Date of Birth</label>
          <input
            type="date"
            id="dateOfBirth"
            name="dateOfBirth"
            value={formData.dateOfBirth}
            onChange={handleChange}
            className={classes.input}
            placeholder="Enter DOB"
          />
        </div>

        <div className={classes.formGroup}>
          <label htmlFor="gstNumber" className={classes.label}>GST Number</label>
          <input
            type="text"
            id="gstNumber"
            name="gstNumber"
            value={formData.gstNumber}
            onChange={handleChange}
            className={classes.input}
            placeholder="Enter GST Number"
          />
        </div>

        <div className={classes.formGroup}>
          <label htmlFor="address" className={classes.label}>Address</label>
          <textarea
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            className={classes.textarea}
            placeholder="Enter Address"
          />
        </div>

        <div className={classes.formGroup}>
          <label htmlFor="selectedCountry" className={classes.label}>Select Country</label>
          <select
            id="selectedCountry"
            name="selectedCountry"
            value={formData.selectedCountry}
            onChange={handleChange}
            className={classes.select}
          >
            <option value="">Select Country</option>
    
          </select>
        </div>

        <div className={classes.formGroup}>
          <label htmlFor="selectedState" className={classes.label}>Select State</label>
          <select
            id="selectedState"
            name="selectedState"
            value={formData.selectedState}
            onChange={handleChange}
            className={classes.select}
          >
            <option value="">Select State</option>
            {
            states?.map((state,i) => (
              <option key={i} value={state.code}>{state.state}</option>
            ))} 
          </select>
        </div>

        <div className={classes.formGroup}>
          <label htmlFor="selectedCity" className={classes.label}>Select City</label>
          <select
            id="selectedCity"
            name="selectedCity"
            value={formData.selectedCity}
            onChange={handleChange}
            className={classes.select}
          >
            <option value="">Select City</option>
             {
            cities?.map((city,i) => (
              <option key={i} value={city.Mcode}>{city.Name}</option>
            ))} 
          </select>
        </div>
        <div>
        {!isSuccess ? (
          <button type="submit" className={classes.button}>
            Create Client
          </button>
        ) : null}
        </div>
        {/* <button className={`${classes[companyColor + 'BG']} `} variant='contained' >Create Client</button> */}
        {/* <button type="submit" className={classes.button}>Create Client  </button> */}
      </form>

    </div>
  );
};

export default FormComponent;