import { Button } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import useStyles from "../PartyStockLedger/style";
import { COMPANY_CODE, GET_ALL_CATEGORIES, GET_VIEW_ACCOUNT_REPORT } from '../../../constant';
import axios from 'axios';

function AccountStatus() {
  const classes = useStyles();

  var today = new Date().toISOString().slice(0, 10); // get today's date in ISO format (yyyy-MM-dd)
  var toda = new Date();
  var tomorrow = new Date(toda);
  tomorrow.setDate(tomorrow.getDate() + 1);
  var nextday = tomorrow.toISOString().slice(0, 10);
  const [fromdate, setfromdate] = useState(today)
  const [todate, settodate] = useState(nextday)
  const [salesummaryclm, Setsalesummaryclm] = useState([]);
  const [acconutstatus, setacconutstatus] = useState([]);
  const [companyColor, setcompanyColor] = useState("")
  const [AllCategories,SetAllCategories] =useState([])
  const [selectedValue, setSelectedValue] = useState('');
  const [isChecked, setIsChecked] = useState(false);


  useEffect(() => {
    setcompanyColor(localStorage.getItem('cmpnyType'))
    //API FOR GET ALL CATEGORIES
    axios.post(GET_ALL_CATEGORIES, {
      "CompanyNumber": COMPANY_CODE,
    }
    ).then((response) => {
      console.log(response)
      if (response.data.IsSuccess === true) {
        SetAllCategories(response.data.Data);
      }
      else {
        // var emptList=[];
        SetAllCategories([]);
      }
    })

  }, [])

  const acconutstatusclm = [
    { field: 'Account Name', headerName: 'Account Name', width: 150 },
    { field: 'Category', headerName: 'Category', width: 150 },
    {
      field: "CompanyNumber",
      headerName: "Company Number",
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params) => {

        return (
          params.row.CompanyNumber === null ? COMPANY_CODE : params.row.CompanyNumber

        );
      }
    },
    { field: 'CreatedDate', headerName: 'Created Date', width: 150 },
    { field: 'Credit', headerName: 'Credit', width: 150 },
    { field: 'CURRENCY', headerName: 'Currency', width: 150 },
    { field: 'Dabit', headerName: 'Debit', width: 150 },
    { field: 'FormDate', headerName: 'Form Date', width: 150 },
    { field: 'Mcode', headerName: 'Mcode', width: 150 },
    { field: 'ToDate', headerName: 'To Date', width: 150 },
    { field: 'chkLocalCurrency', headerName: 'Local Currency', width: 150 },
    { field: 'classification', headerName: 'Classification', width: 150 },
    { field: 'closingCredit', headerName: 'Closing Credit', width: 150 },
    { field: 'closingDabit', headerName: 'Closing Debit', width: 150 },
    { field: 'openingCredit', headerName: 'Opening Credit', width: 150 },
    { field: 'openingDabit', headerName: 'Opening Debit', width: 150 },
  ]


  const loadData = () => {
    debugger;
    axios.post(GET_VIEW_ACCOUNT_REPORT, {
      "CompanyNumber": COMPANY_CODE, "FormDate": fromdate, "ToDate": todate, "chkLocalCurrency": isChecked, "Category": selectedValue
    }
    ).then((response) => {
      console.log(response)
      if (response.data.IsSuccess === true) {
        setacconutstatus(response.data.Data);
        let obj = {};
        const keys = Object.keys(response.data.Data[0]);
        keys.map((data, i) => {
          if (i === keys.length - 1) {
            obj = "";
          }
          else {
            if (data === "Date") {
              obj = {
                field: "data",
                headerName: "Created AT",
                width: 200,
                disableClickEventBubbling: true,
                renderCell: (params) => {

                  return (
                    <p className=' mt-2 f-14'>{params?.row?.Date?.split("T")[0]}</p>
                  );
                }
              };

            }
            else {
              obj = { "field": data, "headerName": data, width: 250 };
            }
            Setsalesummaryclm(salesummaryclm => [...salesummaryclm, obj]);
          }

        });
      }
      else {
        // var emptList=[];
        setacconutstatus([]);
      }
    })
  }


  return (
    <div className="balacepoupformmain " >
      <div className="accountStatusform" style={{ color: '#0b5aa4', paddingTop: '10px', width: '100%', display: 'flex', justifyContent: 'space-between' }}>

        <div className='accountStatusform'>
          <div >
            <span >Account Classification </span>
            <select style={{ width: '170px' }} onChange={(e) => setSelectedValue(e.target.value)}>
              {
                AllCategories.map((item,i)=>(
                   <option value={item.categoryCode}>{item.categoryName}</option>
                ))
              }
            </select>

          </div>
          <div className='accountStatusform_secondgrid' >
            <p >Financial Year : <span>
              <input type="date" value={fromdate} onChange={(e) => setfromdate(e.target.value)} />
              <input type="date" value={todate} onChange={(e) => settodate(e.target.value)} />
            </span> </p>

          </div>
          <div>
            <div className='accontstatus_checkbox'>
            <input type="checkbox" style={{ width:'20px', height:'20px', marginTop:'3px' }} id="one" name="sales_officer" checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)}/><span className='accountStatusform_secondgrid_span' style={{ fontSize: '12px', marginLeft:"5px", marginBottom:'4px' }}>Report in Local Currency</span>
          </div></div>
          <div className="d-block collectionreportbtns" style={{ justifyContent: 'space-between', width: '170px', marginTop:'-5px' }}>
            <button type="button" className={` accountStatusformbtn ${classes[companyColor?.split(',')[0]]}  my-1`} onClick={loadData}>LOAD DATA</button>

          </div>
        </div>
      </div>


      <div className='table animatingBottom whiteheader' style={{ height: "500px", width: "100%", background: '#fff' }}>
        <DataGrid
          rows={acconutstatus}
          columns={salesummaryclm}
          pageSize={7}
          className={classes[companyColor?.split(',')[0]]}
          // onCellClick={handlePendingRfqsCellClick}
          getRowId={(row) => row.MCODE}
          // checkboxSelection

          {...acconutstatus}
        />
      </div>

    </div>

  );
}
export default AccountStatus;