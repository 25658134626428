import { Button } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import useStyles from "../PartyStockLedger/style";
function AgeingAnalysis() {
    const classes = useStyles();
    const [TrailBalance, setTrailBalance] = useState([]);
    const [isLoading,setisLoading] = useState(false)
    const [companyColor,setcompanyColor] = useState("")

    useEffect(()=>{
        setcompanyColor(localStorage.getItem('cmpnyType'))
    },[])


    const TrailBalanceRow = [
        { field: 'Voucher', headerName: 'Voucher', width: 150 },
        { field: 'Nart', headerName: 'Naration', width: 150 },
        { field: 'Dabit', headerName: 'Debit', width: 150 },
        { field: 'Credit', headerName: 'Credit', width: 150 },
        { field: 'Currency', headerName: 'Currency', width: 150 },
        { field: 'atVal', headerName: '@', width: 150 },
    ]


    return (
       <>
         {
            isLoading?  <div className='Commonloader'>
            </div>:null
          }
         <div className="balacepoupformmain AgeingAnalysis_main" >
            <div className="balacepoupformleft  leftpannelBG">

               
                <div className="row">
                    <div className="col-sm-6 col-6 col-md-6" >
                        <div className="scrollerDiv">


                        </div>
                        <input type="checkbox" className="form-check-input" id="" name="" value="" /> &nbsp; Clear All
                    </div>
                    <div className="col-sm-6 col-6 col-md-6">
                        <div className="scrollerDiv">


                        </div>
                        &nbsp;<input type="checkbox" className="form-check-input" id="" name="" value="" />&nbsp; Clear All
                    </div>

                </div>
               
                <div className="row">
                    <div className="col-sm-4 col-4 col-md-4" >
                        <p className='text-center' style={{marginBottom:"-10px"}}>Category </p>
                        <div className="scrollerDiv">


                        </div>
                        &nbsp;<input type="checkbox" className="form-check-input" id="" name="" value="" /> &nbsp; Clear All
                    </div>
                    <div className="col-sm-4 col-4 col-md-4">
                        <p className='text-center' style={{marginBottom:"-10px"}}>Area</p>
                        <div className="scrollerDiv">


                        </div>
                        &nbsp;<input type="checkbox" className="form-check-input" id="" name="" value="" />&nbsp; Clear All

                    </div>
                    <div className="col-sm-4 col-4 col-md-4">
                        <p className='text-center' style={{marginBottom:"-10px"}}>Franchise</p>
                        <div className="scrollerDiv">


                        </div>
                        &nbsp;<input type="checkbox" className="form-check-input" id="" name="" value="" />&nbsp; Clear All
                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">

                        </div>
                    </div>
                </div>
               
                <div className="row">
                    <div className="col-sm-4 col-4 col-md-4">
                        <div className="">
                            <p className="form-check-label text-dark my-1" for="">Date To : </p>
                        </div>
                    </div>
                    <div className="col-sm-4 col-4 col-md-4" style={{marginBottom:"8px",marginTop:"3px"}}>
                        <div className="">
                            <li >
                                <input type="date" className="" id="" name="" value="" />
                            </li>
                        </div>

                    </div>
                    <div className="col-sm-4 col-4 col-md-4">
                        <div className="">
                            <li >
                                &nbsp;  <input type="checkbox" className="form-check-input" id="two" name="" value="" />&nbsp; Exclude
                            </li>
                        </div>
                    </div>

                </div>
               
                <div className="row">
                    <div className="col-sm-4 col-4 col-md-4">
                        <div className="">
                            <p className="form-check-label text-dark my-1" for="inlineRadio2">Account From  :</p>
                        </div>
                    </div>
                    <div className="col-sm-8 col-8 col-md-8" style={{marginBottom:"8px"}}>
                        <div className="">
                            <select>
                                <option>Select account</option>
                            </select>
                        </div>

                    </div>

                </div>
                <div className="row">
                    <div className="col-sm-4 col-4 col-md-4">
                        <div className="">
                            <p className="form-check-label text-dark my-1" for="inlineRadio2">Account To  :</p>
                        </div>
                    </div>
                    <div className="col-sm-8 col-8 col-md-8" style={{marginBottom:"8px"}}>
                        <div className="">
                            <select>
                                <option>Select account</option>
                            </select>
                        </div>

                    </div>

                </div>

               
                <div className="row">
                    <div className="col-sm-4 col-4 col-md-4">
                        <div className="">
                            <p className="form-check-label text-dark my-1" for="inlineRadio2"> Detail/Summary </p>
                        </div>
                    </div>
                    <div className="col-sm-4 col-4 col-md-4">
                        <div className="">
                            <li >
                                <input type="radio" className="form-check-input" id="" name="sales_officer" value="" />&nbsp; Detailed
                            </li>
                        </div>

                    </div>
                    <div className="col-sm-4 col-4 col-md-4">
                        <div className="">
                            <input type="radio" className="form-check-input" id="" name="sales_officer" value="" />&nbsp; Summarised

                        </div>
                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">

                        </div>
                    </div>
                </div>
               
                <div className="row">
                    <div className="col-sm-4 col-4 col-md-4">
                        <div className="">
                            <p className="form-check-label text-dark my-1" for="inlineRadio2"> Ageing By </p>
                        </div>
                    </div>
                    <div className="col-sm-4 col-4 col-md-4">
                        <div className="">
                            <li >
                                <input type="radio" className="form-check-input" id="" name="sales_officer" value="" />&nbsp; Bill Date
                            </li>
                        </div>

                    </div>
                    <div className="col-sm-4 col-4 col-md-4">
                        <div className="">
                            <input type="radio" className="form-check-input" id="" name="sales_officer" value="" />&nbsp; Ledger Date

                        </div>
                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">

                        </div>
                    </div>
                </div>

               
                <div className='row text-center'>
                    <div className='col-sm-12 col-12 col-lg-12'>
                        <p>  Credit Limit (Days) In Ascending Order</p>

                    </div>
                </div>

              
                <div className="row">
                    <div className="col-sm-6 col-6 col-md-6" >
                        <div className='col'>Credit Limit 1 <input type="text"  style={{width:"40px", marginBottom:"3px"}}/></div>
                        <div className='col'>Credit Limit 2 <input type="text"  style={{width:"40px", marginBottom:"3px"}}/></div>
                        <div className='col'>Credit Limit 3 <input type="text"  style={{width:"40px", marginBottom:"3px"}}/></div>
                       
                    </div>
                    <div className="col-sm-6 col-6 col-md-6">             
                    <div className='col'>Credit Limit 4 <input type="text"  style={{width:"40px", marginBottom:"3px"}}/></div>
                    <div className='col'>Credit Limit 5 <input type="text"  style={{width:"40px", marginBottom:"3px"}}/></div>
                    <div className='col'>Credit Limit 6 <input type="text"  style={{width:"40px", marginBottom:"3px"}}/></div>
                    </div>
                    
                </div>
             <br/>
                <div className='row '>
                    <div className='col'>  <input type="text"  style={{width:"30px", marginTop:"10px", marginBottom:"30px"}}/> </div>
                    <div className='col'> <Button variant="contained"   className={classes[companyColor?.split(',')[0]]} >EXCEL</Button></div>
                    <div className='col'> <Button variant="contained"   className={classes[companyColor?.split(',')[0]]} >VIEW</Button></div>
                    <div className='col'> <Button variant="contained"   className={classes[companyColor?.split(',')[0]]} >PDF</Button></div>
                    <div className='col'> <Button variant="contained"   className={classes[companyColor?.split(',')[0]]} >EXIT</Button></div>
                </div>
           

            </div>
            <div className="balacepoupformright">
                <div className='table animatingBottom' style={{ height: "600px", width: "100%", background: '' }}>
                    <DataGrid
                        rows={TrailBalance}
                        columns={TrailBalanceRow}
                        className={classes[companyColor?.split(',')[0]]}
                        pageSize={5}
                        
                        {...TrailBalance}
                    />
                </div>
            </div>
        </div>
       </>
    );
}

export default AgeingAnalysis;