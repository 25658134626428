import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  card: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },

  visitsNumberContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    paddingBottom: theme.spacing(1),
  },
  progressSection: {
    marginBottom: theme.spacing(1),
  },
  progressTitle: {
    marginBottom: theme.spacing(2),
  },
  progress: {
    marginBottom: theme.spacing(1),
    backgroundColor: 'rgb(236, 236, 236)',
  },
  pieChartLegendWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    marginRight: theme.spacing(1),
  },
  legendItemContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  fullHeightBody: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  tableWidget: {
    overflowX: "auto",
  },
  progressBarPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  progressBarWarning: {
    backgroundColor: theme.palette.warning.main,
  },
  performanceLegendWrapper: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  legendElement: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  legendElementText: {
    marginLeft: theme.spacing(1),
  },
  serverOverviewElement: {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
  },
  serverOverviewElementText: {
    minWidth: 0,
    paddingRight: theme.spacing(2),
  },
  serverOverviewElementChartWrapper: {
    width: "100%",
  },
  mainChartBody: {
    overflowX: "auto",
  },
  mainChartHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.only("xs")]: {
      flexWrap: "wrap",
    },
  },
  mainChartHeaderLabels: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.only("xs")]: {
      order: 3,
      width: "100%",
      justifyContent: "center",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  },
  mainChartHeaderLabel: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(3),
  },
  mainChartSelectRoot: {
    borderColor: theme.palette.text.hint + "80 !important",
  },
  mainChartSelect: {
    padding: 10,
    paddingRight: 25,
  },
  mainChartLegentElement: {
    fontSize: "30px !important",
    marginLeft: theme.spacing(1),
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  },
  // dashboardMainClr: {
  //   color: theme.palette.text.primary,
  // },
  countBody: {
    fontSize: '14px'
  },
  textSecondry:
{
  color:theme.palette.text.light,
},
textCountInfo:
{
  fontstyle: "normal",
  fontWeight: 600,
  fontsize: "24px",
  lineHeight:"35px",
  color: "#FFA134",
  color:theme.palette.text.secondary,
},
cardSecondaryClr:
{
  color:theme.palette.text.secondary,
},
dashSideBtnMain:
{
   textAlign: "end"
 },
 dashSideBtn:
 {
fontSize:"12px",
 backgroundColor: "#fff",
  color: 'Black',
  marginRight:'5px',
   padding: '8px 10px',
   height:"35px",
   width: "100px",
   border: "1px solid #CDCDCD",
    boxShadow: `0px 1px 1px ${theme.palette.text.light}`,
    borderRadius: "10px"
},
row1Card:
{
   height:"85%",
   boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  // overflowX:"scroll"
},
restaurant: {
  color: theme.palette.primary.Rastorant_primary,

},

pos: {
  color: theme.palette.primary.POS_primary,

},
production: {
  color: theme.palette.primary.Production_primary,
  
},
order_management: {
  color: theme.palette.primary.OrderMngmt_primary,
  
},
restaurantBG: {
  backgroundColor: theme.palette.primary.Rastorant_primary,
  color: theme.palette.primary.whiteColor,

},
posBG: {
  backgroundColor: theme.palette.primary.POS_primary,
  color: theme.palette.primary.whiteColor,
},
productionBG: {
  backgroundColor: theme.palette.primary.Production_primary,
  color: theme.palette.primary.whiteColor, 
},
order_managementBG: {
  backgroundColor: theme.palette.primary.OrderMngmt_primary,
  color: theme.palette.primary.whiteColor,
},


}));
