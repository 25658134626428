import { Button, Grid } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import useStyles from "../PartyStockLedger/style";
import { COMPANY_CODE, GET_ALL_CATEGORIES, GET_VIEW_ACCOUNT_REPORT } from '../../../constant';
import axios from 'axios';

function BalanceSheet() {
  const classes = useStyles();

  var today = new Date().toISOString().slice(0, 10); // get today's date in ISO format (yyyy-MM-dd)
  var toda = new Date();
  var tomorrow = new Date(toda);
  tomorrow.setDate(tomorrow.getDate() + 1);
  var nextday = tomorrow.toISOString().slice(0, 10);
  const [fromdate, setfromdate] = useState(today)
  const [todate, settodate] = useState(nextday)


  const [acconutstatus, setacconutstatus] = useState([]);
  const [companyColor, setcompanyColor] = useState("")
  const [AllCategories,SetAllCategories] =useState([])
  const [selectedValue, setSelectedValue] = useState('');
  const [isChecked, setIsChecked] = useState(false);


  useEffect(() => {
    setcompanyColor(localStorage.getItem('cmpnyType'))
    //API FOR GET ALL CATEGORIES
    axios.post(GET_ALL_CATEGORIES, {
      "CompanyNumber": COMPANY_CODE,
    }
    ).then((response) => {
      console.log(response)
      if (response.data.IsSuccess === true) {
        SetAllCategories(response.data.Data);
      }
      else {
        // var emptList=[];
        SetAllCategories([]);
      }
    })

  }, [])

  const acconutstatusclm = [
    { field: 'AccountName', headerName: 'Account Name', width: 150 },
    { field: 'Category', headerName: 'Category', width: 150 },
    {
      field: "CompanyNumber",
      headerName: "Company Number",
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params) => {

        return (
          params.row.CompanyNumber === null ? COMPANY_CODE : params.row.CompanyNumber

        );
      }
    },
    { field: 'CreatedDate', headerName: 'Created Date', width: 150 },
    { field: 'Credit', headerName: 'Credit', width: 150 },
    { field: 'Currency', headerName: 'Currency', width: 150 },
    { field: 'Dabit', headerName: 'Debit', width: 150 },
    { field: 'FormDate', headerName: 'Form Date', width: 150 },
    { field: 'Mcode', headerName: 'Mcode', width: 150 },
    { field: 'ToDate', headerName: 'To Date', width: 150 },
    { field: 'chkLocalCurrency', headerName: 'Local Currency', width: 150 },
    { field: 'classification', headerName: 'Classification', width: 150 },
    { field: 'closingCredit', headerName: 'Closing Credit', width: 150 },
    { field: 'closingDabit', headerName: 'Closing Debit', width: 150 },
    { field: 'openingCredit', headerName: 'Opening Credit', width: 150 },
    { field: 'openingDabit', headerName: 'Opening Debit', width: 150 },
  ]


  


  return (
    <div className="balacepoupformmain " >
      <div className='balanceformleft'>
          <div className='rowprofitlosswd '>

            <p className=''  style={{display:""}}>Statement For The Period From: &nbsp;&nbsp;
            <span className='datefromrow'>
              <input type="date"  value={fromdate} style={{}} onChange={(e) => setfromdate(e.target.value)} />
              </span>
            <span className='datetorow'>
              &nbsp;&nbsp;Period To:&nbsp;&nbsp;
            
              <input type="date" value={todate} onChange={(e) => settodate(e.target.value)} />
            </span> </p>
        
          </div>
          
          <div>
                <p className="" style={{display:"flex"}} for="inlineRadio2">With Opening Balance&nbsp;&nbsp;
                    <input type="radio" className="form-check-input" id="" name="" value="" />&nbsp;&nbsp;Yes&nbsp;&nbsp;
                    <input type="radio" className="form-check-input" id="" name="" value="" />&nbsp;&nbsp;No &nbsp;&nbsp; 
                   </p>
            </div>
            <div>
                <p className="" style={{display:"flex"}} for="inlineRadio2">Print Statement&nbsp;&nbsp;
                    <input type="radio" className="form-check-input" id="" name="" value="" />&nbsp;&nbsp;Statement Only&nbsp;&nbsp;
                    <input type="radio" className="form-check-input" id="" name="" value="" />&nbsp;&nbsp;Statement With Schedule&nbsp;&nbsp;
                    </p>  
            </div>
            <div>
            <p className="" style={{display:"flex"}} >Display Level&nbsp;&nbsp;&nbsp;
            <input type='input' style={{paddingRight:"6px"}}></input>
            </p>
            </div>
            </div>


      <div className='table animatingBottom whiteheader' style={{ height: "500px", width: "100%", background: '#fff' }}>
        <DataGrid
          rows={acconutstatus}
          columns={acconutstatusclm}
          pageSize={7}
          className={classes[companyColor?.split(',')[0]]}
          // onCellClick={handlePendingRfqsCellClick}
          getRowId={(row) => row.Mcode}
          // checkboxSelection

          {...acconutstatus}
        />
      </div>
      <div className='' style={{}} >
                <div className='col ' >
                 <div className='' style={{width:"100%",paddingBottom:"28px", display:"flex"}} >  
                 <Button variant='contained' style={{width:"25%"}} className={classes[companyColor?.split(',')[0]]}>Excel </Button>&nbsp;
                <Button variant="contained" style={{width:"25%"}} className={classes[companyColor?.split(',')[0]]}  >View</Button>&nbsp;  
                <Button variant="contained"  style={{width:"25%"}} className={classes[companyColor?.split(',')[0]]}  >Pdf</Button>&nbsp;
                <Button variant="contained"  style={{width:"25%"}} className={classes[companyColor?.split(',')[0]]}  >Exit</Button>
                </div> 
                </div>
            </div>

    </div>

  );
}
export default BalanceSheet;