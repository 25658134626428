import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import Themes from "./themes";
import App from "./App";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
import reportWebVitals from "./reportWebVitals";
import 'bootstrap/dist/css/bootstrap.css';

ReactDOM.render(
  <ThemeProvider theme={Themes.default}>
<CssBaseline />
<App />
</ThemeProvider>
,
  document.getElementById("root"),
);


//   <LayoutProvider>
//   <UserProvider>
//     <ThemeProvider theme={Themes.default}>
//       <CssBaseline />
//       <App />
//     </ThemeProvider>
//   </UserProvider>
// </LayoutProvider>



reportWebVitals();
