import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
btnForLedger:{
  background:"#0b5aa4",
  color:"white",
},
restaurant: {
  backgroundColor: theme.palette.primary.Rastorant_primary,
  color: theme.palette.primary.whiteColor,
},
pos: {
  backgroundColor: theme.palette.primary.POS_primary,
  color: theme.palette.primary.whiteColor,
},
production: {
  backgroundColor: theme.palette.primary.Production_primary,
  color: theme.palette.primary.whiteColor,
},
order_management: {
  backgroundColor: theme.palette.primary.OrderMngmt_primary,
  color: theme.palette.primary.whiteColor,
},
restaurantTEXT: {
  color: theme.palette.primary.Rastorant_primary,

},

posTEXT: {
  color: theme.palette.primary.POS_primary,

},
productionTEXT: {
  color: theme.palette.primary.Production_primary,
  
},
order_managementTEXT: {
  color: theme.palette.primary.OrderMngmt_primary,
  
},

restaurantBG: {
  backgroundColor: theme.palette.primary.Rastorant_primary,
  color: theme.palette.primary.whiteColor,
},
posBG: {
  backgroundColor: theme.palette.primary.POS_primary,
  color: theme.palette.primary.whiteColor,
},
productionBG: {
  backgroundColor: theme.palette.primary.Production_primary,
  color: theme.palette.primary.whiteColor,
},
order_managementBG: {
  backgroundColor: theme.palette.primary.OrderMngmt_primary,
  color: theme.palette.primary.whiteColor,
},

tableRowEven: {
  backgroundColor: "#f2f2f2",
},

tableRowOdd: {
  backgroundColor: "#ffffff",
},

}
));
