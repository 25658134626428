import React, { useState } from "react";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button, TextField,
  Checkbox
} from "@material-ui/core";
import { useHistory } from "react-router-dom"
import { useTheme } from "@material-ui/styles";
// import { CSVLink } from "react-csv";
import useStyles from "./style";
// styles

import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import { GET_All_ACCOUNT, LOGIN_DETAILS, GET_LEDGER_REPORT, GET_GRAPH_DATA, GET_CATEGORY_SALE_REPORT, COMPANY_CODE, GET_ALL_WAREHOUSES, GET_ALL_TRANSATIN_TYPE, GET_ALL_PARTY, GET_ALL_SPECIFIC_PARTY, GET_PARTY_STOCK_REPORT } from '../../../constant';
import axios from "axios";

export default function Client(props) {
  const classes = useStyles();
  const theme = useTheme();
  let history = useHistory();
  const [isSelectAc, setIsSelectAc] = useState(false);
  const [ldgReport, setLdgReport] = useState([]);
  const [tabledata, settabledata] = useState([]);
  const [ldgAccList, setLdgAccList] = useState([]);
  const [ldgAcList, setLdgAcList] = useState([]);
  const [isLoading, setisLoading] = useState(false)
  const [selectedLdgAccIndex, setSelectedLdgAccIndex] = useState(0);

  var today = new Date().toISOString().slice(0, 10); // get today's date in ISO format (yyyy-MM-dd)
  var toda = new Date();
  var tomorrow = new Date(toda);
  tomorrow.setDate(tomorrow.getDate() + 1);
  var nextday = tomorrow.toISOString().slice(0, 10);
  const [startdate, setstartdate] = useState(today)    // need to set here today variable 
  const [enddate, setenddate] = useState(nextday);
  const [ChartCategory, setChartCategory] = useState([])
  const [warehouse,setwarehouse] = useState([])
  const [transactionType,settransactionType] = useState([])
  const [allpartyTyp,setallpartyTyp] = useState([])
  const [specificParty,setspecificParty] = useState([])
  const [checkedItems, setCheckedItems] = useState([]);
  const [checkedwarehouse, setcheckedwarehouse] = useState([]);
  const [checkedtransactionTYp, setcheckedtransactionTYp] = useState([]);
  const [isCheckedCategory,setisCheckedCategory] = useState(false)
  const [SelectedToValue,setSelectedToValue] = useState("")
  const [SelectedfromValue,setSelectedfromValue] = useState("")

  const [companyColor, setcompanyColor] = useState("")
  const LedgerTblRow = [
    {
      field: "CreatedDate",
      headerName: "Created AT",
      width: 95,
      disableClickEventBubbling: true,
      renderCell: (params) => {

        return (
          <p className=' mt-2 f-14'>{params?.row?.CreatedDate?.split("T")[0]}</p>
        );
      }
    },
    { field: 'Voucher', headerName: 'Voucher', width: 150 },
    { field: 'Nart', headerName: 'Naration', width: 150 },
    { field: 'Dabit', headerName: 'Debit', width: 150 },
    { field: 'Credit', headerName: 'Credit', width: 150 },
    { field: 'Currency', headerName: 'Currency', width: 150 },
    { field: 'atVal', headerName: '@', width: 150 },
  ]
  const [ledgerAc, setLedgerAc] = useState({
    "FYear": LOGIN_DETAILS.FINANCIAL_YEAR
  });
  const [ledger, setLedger] = useState({
    "FormDate": new Date(),
    "ToDate": new Date(),
    "chkLocalCurrency": 0,
    "Mcode": ""
  });



  useEffect(() => {

// gropu selection

    // axios.post(GET_CATEGORY_SALE_REPORT,
    //   { "CompanyNumber": 304, "FormDate": startdate, "ToDate": enddate })
    //   .then((response) => {
    //     console.log(" ITEM Category ")
    //     console.log(response)
    //     if (response.data.IsSuccess === true) {
    //       console.log(response.data.Data);
    //       setChartCategory(response.data.Data)

    //     }
    //   })

//all ware house
      // axios.post(GET_ALL_WAREHOUSES,
      //   { "CompanyNumber": 304 })
      //   .then((response) => {
      //     console.log("ALL WARE HOUSE ")
      //     console.log(response)
      //     if (response.data.IsSuccess === true) {
      //       console.log(response.data.Data);
      //       setwarehouse(response.data.Data)
  
      //     }
      //   })

        // ALL TRANSACTIO TYPE 

      // axios.post(GET_ALL_TRANSATIN_TYPE,
      //   { "CompanyNumber": 210 })
      //   .then((response) => {
      //     console.log("TRANSACTIION TYPE")
      //     console.log(response)
      //     if (response.data.IsSuccess === true) {
      //       console.log(response.data.Data);
      //       settransactionType(response.data.Data)
  
      //     }
      //   })

   // get all party 

  //  axios.post(GET_ALL_PARTY,
  //   { "CompanyNumber": 210 })
  //   .then((response) => {
  //     console.log("get all party ")
  //     console.log(response)
  //     if (response.data.IsSuccess === true) {
  //       console.log(response.data.Data);
  //       setallpartyTyp(response.data.Data)

  //     }
  //   })

    // get specific party 

    // axios.post(GET_ALL_SPECIFIC_PARTY,
    //   { "CompanyNumber": 210 })
    //   .then((response) => {
    //     console.log("get specific party  ")
    //     console.log(response)
    //     if (response.data.IsSuccess === true) {
    //       console.log(response.data.Data);
    //       setspecificParty(response.data.Data)
  
    //     }
    //   })


// TABLE DATA 
// axios.post(GET_PARTY_STOCK_REPORT,
//   { "CompanyNumber": 210 })
//   .then((response) => {
//     console.log("get PARTY STOCK")
//     console.log(response)
//     if (response.data.IsSuccess === true) {
//       console.log(response.data.Data);


//     }
//   })
      
      
  

  }, [startdate, enddate])


  useEffect(() => {
    setcompanyColor(localStorage.getItem('cmpnyType'))
    //document.querySelector('.TOGGLEicon').click();
    var a = window.location.href;
    axios.post(GET_All_ACCOUNT, ledgerAc
    ).then((response) => {
      if (response.data.IsSuccess === true) {
        setLdgAcList(response.data.Data);
      }
      else {
        // var emptList=[];
        setLdgAcList([]);
      }
    })
  }, []);


  const acTypeClicked = (type) => {
    if (type === "Selected") {
      setIsSelectAc(true);
    }
    else {
      setIsSelectAc(false);
    }
    setSelectedLdgAccIndex(0);
    setLedger({ ...ledger, "Mcode": "" });
  }
  const getLedgerReportInNxtBtn = () => {
    setisLoading(true)
    //localStorage.setItem('ldgAccList_index', selectedLdgAccIndex);

    setSelectedLdgAccIndex(selectedLdgAccIndex + 1);
    axios.post(GET_LEDGER_REPORT, {
      "FormDate": ledger.FormDate,
      "ToDate": ledger.ToDate,
      "chkLocalCurrency": ledger.chkLocalCurrency,
      "Mcode": ldgAccList[selectedLdgAccIndex + 1]
    }
    ).then((response) => {
      if (response.data.IsSuccess === true) {
        setLdgReport(response.data.Data);
        setisLoading(false)

      }
      else {
        // var emptList=[];
        setLdgReport([]);
        setisLoading(false)
      }
    })
  }
  const onhandleAcChanges = (e) => {
    setLdgAccList(ldgAccList => [...ldgAccList, e.target.value]);
    setLedger({ ...ledger, "Mcode": e.target.value });
  }
  const getLedgerReport = () => {
    setisLoading(true)
    if (ledger.FormDate === "" || ledger.ToDate === "" || ledger.Mcode === "") {
      alert("Account and from date and to date should not blank.");
      setisLoading(false)
    }
    else {
      axios.post(GET_LEDGER_REPORT,
        {
          "FormDate": ledger.FormDate,
          "ToDate": ledger.ToDate,
          "chkLocalCurrency": ledger.chkLocalCurrency,
          "Mcode": ldgAccList[0]
        }
      ).then((response) => {
        if (response.data.IsSuccess === true) {
          setLdgReport(response.data.Data);
          setSelectedLdgAccIndex(0);
          setisLoading(false)
        }
        else {
          // var emptList=[];
          setLdgReport([]);
          setisLoading(false)
        }
      })
    }
  }

  function getValues() {
    var checkboxes = document.querySelectorAll('#itemCategryChckbox');
    var  itemCategories = Array.from(checkboxes).filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.value);
    itemCategories = itemCategories.map(str => str.replace(/\r?\n|\r/g, '')).join(",")
  //
  var checkboxes = document.querySelectorAll('#warehouseChckbox');
  var  warehouses = Array.from(checkboxes).filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.value);
  warehouses = warehouses.map(str => str.replace(/\r?\n|\r/g, '')).join(",")
  //
  var checkboxes = document.querySelectorAll('#transactionChckbox');
  var  transactionTypes = Array.from(checkboxes).filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.value);
  transactionTypes = transactionTypes.map(str => str.replace(/\r?\n|\r/g, '')).join(",")
  

  
    return [itemCategories, warehouses, transactionTypes];
  }



  const goBackPage = () => {
    //navigate(-1);
    history.goBack();
  }
  const handleLedgerChange = (e) => {
    if (e.target.name === "chkLocalCurrency") {
      setLedger({ ...ledger, [e.target.name]: e.target.checked });
    }
    else {
      if (e.target.name === "Mcode") {
        if (e.target.checked) {
          setLdgAccList(ldgAccList => [...ldgAccList, e.target.value]);
        }
        else {
          if (ldgAccList.indexOf(e.target.value) > -1) {
            ldgAccList.splice(ldgAccList.indexOf(e.target.value), 1);
          }
        }
      }
      setLedger({ ...ledger, [e.target.name]: e.target.value });
    }
  }

  return (
    <>
      {
        isLoading ? <div className='Commonloader'>
        </div> : null
      }
      <div className="balacepoupformmain saleregister_main" >
        <div className="balacepoupformleft" style={{width: "35%"}} >

         
          <div className="scrolltype">
            <div className="row Saleregister_2">
              <div className="row">
            <div className="col-sm-6 col-6 col-md-6">
              <div className="mb-0">
                <h6 className="form-check-label text-dark my-1 "style={{ fontSize: '12px' }} for="inlineRadio2">From Date :</h6>
                <input type="date" className="form-control" style={{ fontSize: '12px' }} id="FDate" name="FormDate" value={startdate}
                  onChange={(e) => setstartdate(e.target.value)} />
              </div>
            </div>
            <div className="col-sm-6 col-6 col-md-6">
              <div className="mb-0">
                <h6 className="form-check-label text-dark my-1" style={{ fontSize: '12px' }}for="inlineRadio2">To Date :</h6>
                <input type="date" className="form-control" style={{ fontSize: '12px' }} id="ToDate" name="ToDate" value={enddate}
                  onChange={(e) => setenddate(e.target.value)} />
              </div>
            </div>
          </div>
          </div>
             
            <div className="row Saleregister_2">
              <div className="col-sm-6 col-6 col-md-6">
              <p className="form-check-label text-dark my-1 text-center" style={{ textAlign: 'center' }} for="inlineRadio2">WAREHOUSE</p>
                <div className="scrollerDiv">
                {/* {warehouse.length == 0 ? "NO warehouse Found" : ""} */}
              {
                warehouse?.map((item ,i)=>(
                  <li>
                  <input type="checkbox" className="form-check-input" id="warehouseChckbox" name="" value={item.Type} /> &nbsp;{item.Category}</li>
                ))
              }
                </div>

              </div>
              <div className="col-sm-6 col-6 col-md-6">
              <p className="form-check-label text-dark my-1 text-center" style={{ textAlign: 'center' }} for="inlineRadio2">ITEM CATEGORY</p>
                <div className="scrollerDiv">
                {/* {transactionType.length == 0 ? "NO Transaction Type Found" : ""} */}
              {
                transactionType?.map((item ,i)=>(
                  <li>
                  <input type="checkbox" className="form-check-input" id="transactionChckbox" name="" value={item.Type} /> &nbsp;{item.Category}</li>
                ))
              }
                </div>

              </div>
            </div>
          </div>
          <div className="row Saleregister_2">
          <div className="mb-0 Saleregister_2grid">
            <span style={{ color: '#000' }}>Specific Party &nbsp;</span>
            <select  style={{width:'73%'}}  onChange={(e) => setSelectedfromValue(e.target.value)}  >
              <option value="">Please select any value</option>
              {
                allpartyTyp?.map((item,i)=>(
                  <option value={item.Name}>{item.Name}</option>
                ))
              }
            </select>
            
            {/* <span style={{ color: '#000' }}>Item To &nbsp;&nbsp; &nbsp;&nbsp;</span>
            <select  style={{width:'76%'}}  onChange={(e) => setSelectedToValue(e.target.value)}>
              <option value="">Please select any value</option>
              {
                allpartyTyp?.map((item,i)=>(
                  <option value={item.Name}>{item.Name}</option>
                ))
              }
            </select> */}
          </div>
          </div>
        
          <div className="row Saleregister_2">
          <div className="mb-0 Saleregister_2grid">
            <span style={{ color: '#000' }}>Report View &nbsp;&nbsp;&nbsp;</span>
            <div className="form-check form-check-inline ">
              <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="AToA" defaultChecked={true} />
              <label className="form-check-label text-dark" for="inlineRadio1">Detail</label>

            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="Selected" />
              <label className="form-check-label text-dark" for="inlineRadio2">Summary</label>
            </div>
          </div>
          </div>
          
        
          <div className="row Saleregister_3">
          <div className="mb-0 Saleregister_2grid">
          <span style={{ color: '#000' }}>Print Report For &nbsp;</span>
            <div className="form-check form-check-inline ">
              <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="AToA" defaultChecked={true} />
              <label className="form-check-label text-dark" for="inlineRadio1">Passed</label>

            </div>
            <div className="form-check form-check-inline ">
              <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="AToA" defaultChecked={true} />
              <label className="form-check-label text-dark" for="inlineRadio1">Un-Passed</label>

            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="Selected" />
              <label className="form-check-label text-dark" for="inlineRadio2">Both</label>
            </div>
          </div>
          </div>
          <div className="row"  style={{ fontSize: '12px',  padding:'1px', background:'#e7e7ec',color:'#111'  }}>
          <div className="col-sm-6 col-6 col-md-6">
          <p className="form-check-label text-dark my-1 text-center" style={{ textAlign: 'center' }} for="inlineRadio2">Form Type</p>
          </div>
          <div className="col-sm-6 col-6 col-md-6">
          <p className="form-check-label text-dark my-1 text-center" style={{ textAlign: 'center' }} for="inlineRadio2">Nature</p>
          </div>
          </div>
          <div className="scrolltype">
            <div className="row Saleregister_2">
           
          <div className="col-sm-3 col-3 col-md-3">
            
               
                <div className="scrollerDiv">
               
              {
                warehouse?.map((item ,i)=>(
                  <li>
                  <input type="checkbox" className="form-check-input" id="warehouseChckbox" name="" value={item.Type} /> &nbsp;{item.Category}</li>
                ))
              }
                </div>

              </div>
              <div className="col-sm-3 col-3 col-md-3">
               
                <div className="scrollerDiv">
              
              {
                transactionType?.map((item ,i)=>(
                  <li>
                  <input type="checkbox" className="form-check-input" id="transactionChckbox" name="" value={item.Type} /> &nbsp;{item.Category}</li>
                ))
              }
                </div>

              </div>
            
            
              <div className="col-sm-3 col-3 col-md-3">          
                <div className="scrollerDiv">
              {
                warehouse?.map((item ,i)=>(
                  <li>
                  <input type="checkbox" className="form-check-input" id="warehouseChckbox" name="" value={item.Type} /> &nbsp;{item.Category}</li>
                ))
              }
                </div>

              </div>
              <div className="col-sm-3 col-3 col-md-3">               
                <div className="scrollerDiv">               
              {
                transactionType?.map((item ,i)=>(
                  <li>
                  <input type="checkbox" className="form-check-input" id="transactionChckbox" name="" value={item.Type} /> &nbsp;{item.Category}</li>
                ))
              }
                </div>

              </div>
              </div>
              </div>

          <div className="row Saleregister_2">
          <div className="mb-0 PricedStockLedger_2grid">
            <span style={{ color: '#000' }}>Print Sequence &nbsp;</span>
            <div className="form-check form-check-inline ">
              <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="AToA" defaultChecked={true} />
              <label className="form-check-label text-dark" for="inlineRadio1">Challan Date</label>

            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="Selected" />
              <label className="form-check-label text-dark" for="inlineRadio2">Passing Date</label>
            </div>
          </div>
          </div>
          <div className="row Saleregister_4">
          <div className="mb-0 Saleregister_2grid">
          <input className="form-check-input" type="checkbox"  />
              <label className="form-check-label text-dark" >&nbsp;&nbsp;Print Claimable Amount In &nbsp;&nbsp;</label>
          <input className="form-check-input" type="checkbox"  />
              <label className="form-check-label text-dark" >&nbsp;&nbsp;PrintOverHeadAccount</label>

          </div>
          <div className="mb-0 Saleregister_2grid">
          <input className="form-check-input" type="checkbox"  />
              <label className="form-check-label text-dark" >&nbsp;&nbsp;Print Party Address &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
          <input className="form-check-input" type="checkbox"  />
              <label className="form-check-label text-dark" >&nbsp;&nbsp;Print Shipment Details</label>
          
          </div>
          </div>
          {/* <div className="mb-2">
            <span style={{ color: '#000' }}>Print To &nbsp;</span>
            <select  style={{width:'80%'}}>
              <option value="">Select Specific Item</option>
              {
                specificParty?.map((item,i)=>(
                  <option value={item.Mcode}>{item.Name}</option>
                ))
              }
            </select>
          </div> */}  
          <div className="row Saleregister_2">
          <div className="d-flex mt-2" style={{ width: '89%' }}>

            <Button variant="contained" className={classes[companyColor?.split(',')[0]]} onClick={getLedgerReport}>EXCEL </Button>&nbsp;
            <Button variant="contained" className={classes[companyColor?.split(',')[0]]} onClick={getLedgerReport}>VIEW</Button>&nbsp;
            <Button variant="contained" className={classes[companyColor?.split(',')[0]]} onClick={getLedgerReport}>PDF</Button>&nbsp;
            <Button variant="contained" className={classes[companyColor?.split(',')[0]]} onClick={goBackPage}>EXIT</Button>
          </div>
          </div>

        </div>
        <div className="balacepoupformright">
          <div className='table animatingBottom' style={{ height: "570px", width: "100%" }}>
            <DataGrid
              rows={ldgReport}
              columns={LedgerTblRow}
              // loading={loading}
              pageSize={5}
              className={`${classes[companyColor?.split(',')[0]]}`}
              getRowId={(row) => row.Voucher}
              onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRows = tabledata.filter((row) =>
                  selectedIDs.has(row.Voucher),

                );

              }}
              {...ldgReport}
            />
          </div>
        </div>
      </div>
    </>
  );
}

