

//const API_DOMAIN = "http://dev-api.saitec.in";
 //const API_DOMAIN ="https://192.168.1.25:45456" // Local/
 const API_DOMAIN ="https://api.saitec.in" // PROD

 //---------------Login API-----------------------
export const LOGIN_API = `${API_DOMAIN}/Account/login`;
 //---------- GET COMPANY DETAILS----------------
export const GET_COMPANY_DETAILES = `${API_DOMAIN}/Company/GetCompanyDetailsV2`;
//------------Login API------------------
// export const LOGIN_API = `${API_DOMAIN}/OrderManagment/login`;
 //---------- GET PENDING INVOICE FOR DASHBOERD----------------
export const GET_PENDING_INVOICE_COUNT = `${API_DOMAIN}/Dashboard/getPendingInvoiceCount`; //changed
  //---------- GET CURRENT LIVE ENQUIRIS FOR DASHBOERD----------------
export const GET_CURRENT_LIVE_ENQUIRIS_COUNT = `${API_DOMAIN}/Dashboard/getCurrentLiveEnquirisCount`;
//---------- GET CURRENT LIVE ENQUIRIS FOR DASHBOERD----------------
//export const GET_CURRENT_ALL_LIVE_ENQUIRIS = `${API_DOMAIN}/OrderManagment/getAllCurrentLiveEnquiry`;
//---------- GET ALL PENDING RFQS DASHBOERD----------------
export const GET_CURRENT_ALL_PENDING_RFQ = `${API_DOMAIN}/OrderManagment/getAllPendingRFQ`;
//---------- GET ALL PENDING RFQS DASHBOERD----------------
 export const GET_CURRENT_ALL_PENDING_INVOICE = `${API_DOMAIN}/OrderManagment/getAllPendingInvoice`;
//---------- GET ALL DEBITOR BALANCE----------------
 export const GET_DEBITOR_BALANCE = `${API_DOMAIN}/OrderManagment/getAllDebitorBalance`;
//---------- GET ALL creditor balance----------------
export const GET_CREDITOR_BALANCE = `${API_DOMAIN}/OrderManagment/getAllCreditorBalance`;
//---------- GET PENDING RFQ FOR DASHBOERD----------------
export const GET_PENDING_RFQ_COUNT = `${API_DOMAIN}/Dashboard/getPendingRFQ`;
        //---------- GET TOP client order FOR DASHBOERD----------------
 export const GET_TOP_CLIENT_ORDER_COUNT = `${API_DOMAIN}/Dashboard/getTopClientOrderCount`;
 //---------- GET TOP VENDOR FOR DASHBOERD----------------
export const GET_TOP_VENDOR_COUNT = `${API_DOMAIN}/Dashboard/getTopVendorCount`;
  //---------- GET TOP CLIENT FOR DASHBOERD----------------
export const GET_SALES_ANALYSIS_COUNT = `${API_DOMAIN}/Dashboard/getSaleAnalysisCount`;

export const GET_TOP_CLIENT_COUNT = `${API_DOMAIN}/Dashboard/getTopCilentCount`;
  //---------- GET GRAPH DATE FOR DASHBOERD----------------
  export const GET_GRAPH_DATA = `${API_DOMAIN}/Dashboard/getGraphData`;

  export const GET_CURRENT_ALL_LIVE_ENQUIRIS = `${API_DOMAIN}/OrderManagment/getAllCurrentLiveEnquiry`;

//---------- GET ALL ACCOUNT LIST----------------
export const GET_All_ACCOUNT = `${API_DOMAIN}/Parameter/getAllAccount`;
//---------- GET LEDGER ACCOUNT----------------
export const GET_LEDGER_REPORT = `${API_DOMAIN}/MIS/viewLedgerReport`;
 //---------- GET LEDGER ACCOUNT----------------
 export const GET_COLLECTION_REPORT = `${API_DOMAIN}/MIS/viewCollectionReport`;
 //---------- GET ALL COUTERS----------------
 export const GET_ALL_COUTER = `${API_DOMAIN}/Parameter/getAllCounter`;
 //---------- GET ALL COUTERS----------------
export const GET_ALL_USER = `${API_DOMAIN}/Parameter/getAllUser`;
//---------------------- GET All categoriesREPORT ----------------------------------
export const GET_ALL_CATEGORIES = `${API_DOMAIN}/Parameter/getAllCategory`;
//---------- GET SALE SUMMARY Report----------------
export const GET_SALE_SUMMARY_REPORT = `${API_DOMAIN}/MIS/viewSaleSummayReport`;
//---------- GET SALE detailed Sale report----------------
export const GET_DETAILED_SALE_REPORT = `${API_DOMAIN}/MIS/viewDetailedSaleReport`;
//---------- GET SALE sale return  report----------------
export const GET_SALE_RETURN_REPORT = `${API_DOMAIN}/MIS/viewSaleReturnReport`;
//---------------------- LGET SALE REPORT ----------------------------------
export const GET_SALE_REPORT = `${API_DOMAIN}/MIS/viewSaleReport`;
//---------------------- GET counter details REPORT----------------------------------
export const GET_COUNTER_DETAIL_REPORT = `${API_DOMAIN}/MIS/viewCounterDetailReport`;
//---------------------- GET BILL COMMISSION REPORT ----------------------------------
export const GET_BILL_COMMISSION_REPORT = `${API_DOMAIN}/MIS/viewBillCommissionReport`;
//---------------------- GET CLIENT WISE COMMISSION REPORT ----------------------------------
export const GET_CLIENT_WISE_COMMISSION_REPORT = `${API_DOMAIN}/MIS/viewClientWiseCommissionReport`;
//---------------------- GET SUMMARY COMMISSION REPORT ----------------------------------
export const GET_SUMMARY_COMMISSION_REPORT = `${API_DOMAIN}/MIS/viewSummaryCommissionReport`;
//---------------------- GET ITEM SUMMARY REPORT ----------------------------------
export const GET_ITEM_SUMMARY_REPORT = `${API_DOMAIN}/MIS/viewItemSummayReport`;
//----------------Function to get view Category Sale Report------------------
export const GET_CATEGORY_SALE_REPORT= `${API_DOMAIN}/MIS/viewCategorySaleReport`;
//----------------Function to get Allwarehouse party ledger------------------
export const GET_ALL_WAREHOUSES= `${API_DOMAIN}/Parameter/getAllwarehouse`;
//----------------Function to get All Trnsctn Type party ledger------------------
export const GET_ALL_TRANSATIN_TYPE= `${API_DOMAIN}/Parameter/getAllTrnsctnType`;
//----------------Function to get All PARTY------------------
export const GET_ALL_PARTY= `${API_DOMAIN}/Parameter/getAllParty`;
//----------------Function to get specific party------------------
export const GET_ALL_SPECIFIC_PARTY= `${API_DOMAIN}/Parameter/getSpecificParty`;
 //----------------Function to get view Table details Report------------------
export const GET_TABLE_DETAILS_REPORT= `${API_DOMAIN}/MIS/viewTableDetailsReport`;
 //----------------Function to get view  KOT Details Report------------------
 export const GET_VIEW_KOT_DETAILS_REPORT= `${API_DOMAIN}/MIS/viewKOTDetailsReport`;
  //----------------Function to get view  Financial Details Report------------------
  export const GET_VIEW_FINANCIAL_DETAILS_REPORT= `${API_DOMAIN}/MIS/viewFinancialReport`;
    //----------------Function to get view  CLIENT TRACKING  Report------------------
    export const GET_VIEW_CLIENT_TRACKING_DETAILS_REPORT= `${API_DOMAIN}/MIS/viewClientTrackingReport`;
      //----------------Function to get view  EXPENCE Report------------------

    //  export const GET_VIEW_EXPENCE_DETAILS_REPORT= `${API_DOMAIN}/Collection/viewExpenceReport`;
//----------------Function to get view  Account status Report ------------------
  export const GET_VIEW_ACCOUNT_REPORT= `${API_DOMAIN}/MIS/viewAccountStatusReport`;
  //----------------Function to get view  trail balance Report ------------------
  export const GET_TRAIL_BALANCE_REPORT= `${API_DOMAIN}/MIS/getTrialBalanceReport`;
    //----------------Function to get view  PartyStock Ledger Report------------------
    export const GET_PARTY_STOCK_REPORT= `${API_DOMAIN}/MIS/viewPartyStockLedgerReport`;

 export const GET_VIEW_EXPENCE_DETAILS_REPORT= `${API_DOMAIN}/MIS/viewExpenceReport`;


 //----------------Account Master----------------------------
//  export const GET_ALL_CATEGORY= `${API_DOMAIN}/Parameter/getAllCategory`;
 export const GET_ALL_CITY= `${API_DOMAIN}/Parameter/GetCity`;
 export const GET_ALL_STATE= `${API_DOMAIN}/Parameter/GetState`;
 export const GET_ITEMS= `${API_DOMAIN}/Parameter/GetItems`;
 export const GET_CLIENTS=`${API_DOMAIN}/Parameter/GetClient`;
 export const PLACE_ORDER=`${API_DOMAIN}/Parameter/SaleOrderPlace`;
 export const CREATE_NEW_CLIENT=`${API_DOMAIN}/Parameter/CreateNewClient`;



 //---------- GET Debitor Balance FOR DASHBOERD----------------
 export const GET_DEBITOR_BALACNCE_COUNT = `${API_DOMAIN}/Dashboard/getDebitorbalanceCount`;
//---------- GET Creditor Balance FOR DASHBOERD----------------
 export const GET_CREDITOR_BALACNCE_COUNT = `${API_DOMAIN}/Dashboard/getCreditorbalanceCount`;
//---------- GET ALL PENDING RFQS DASHBOERD----------------
 export const GET_ALL_PENDING_INVOICE = `${API_DOMAIN}/OrderManagment/getAllPendingInvoice`;
// PRODUCTION DASHBOARD QUERY
export const GET_ITEMS_BELOW_ROL = `${API_DOMAIN}/Dashboard/getItemBelowROL`;

export const GET_TOTAL_BILL_OF_DAY = `${API_DOMAIN}/Dashboard/getTotalBillTotheday`;

export const GET_TOP_TEN_SALE_ITEM= `${API_DOMAIN}/Dashboard/getTopTenSaleItem`;

//Restaurant Dashboard Query
export const GET_ITEM_BELOW_ROL = `${API_DOMAIN}/Dashboard/getItemBelowROL`;

//---------- GET ALL PENDING RFQS DASHBOERD----------------
 export const GET_TOTAL_BILL_OF_Day= `${API_DOMAIN}/Dashboard/getTotalBillTotheday`;
 //---------- GET total cash by date DASHBOERD----------------
 export const GET_TOTAL_COLLECTION_BY_DATE= `${API_DOMAIN}/Dashboard/getTotalCollectionByDate`;

//----------------Item dashboard----------------------------//
export const GET_MASTER_FROM_ITEM = `${API_DOMAIN}/api/ItemDashboard/getMasterFormitem`;
export const GET_ITEM = `${API_DOMAIN}/Parameter/GetItems`;
export const GET_QOH_ITEM = `${API_DOMAIN}//api/ItemDashboard/getQOHitem`;


 // _____________________________________________ PRODUCTION __________________________________________ );

 export const GET_TOTAL_FG_STOCK= `${API_DOMAIN}/Dashboard/getTotalFGstock`;

 export const GET_TOTAL_FG_STOCK_CATEGORY= `${API_DOMAIN}/Dashboard/FGstockCategory`;

 export const GET_ALL_FG_STOCK= `${API_DOMAIN}/Dashboard/AllFGstock`;

 //_____item produced 

 export const GET_TOTAL_ITEM_PRODUCED= `${API_DOMAIN}/Dashboard/getTotalItemProduce`;

 export const GET_TOTAL_ITEM_PRODUCED_CATEGORY= `${API_DOMAIN}/Dashboard/ItemProduceCategory`;

 export const GET_ALL_ITEM_PRODUCED= `${API_DOMAIN}/Dashboard/AllItemsProduce`;

 export const GET_SALE_ORDER_GRID= `${API_DOMAIN}/Dashboard/SaleOrderGrid`;

  //---------------GET COMPANY TYPE----------------
export const COMPANY_TYPE={
    "PRODUCTION":"PRODUCTION",
    "RESTAURANT":"RESTAURANT",
    "ORDER_MANAGEMENT":"ORDER_MANAGEMENT",
    "POS":"POS",
}

export const LOGIN_DETAILS={
  "FINANCIAL_YEAR":"2020-2021",
  "WAREHOUSE":"110NOIDA",
    "CHSERIES_RSL":"10/",
  "OFAC":"110NOIDA"
}
  export const COMPANY_CODE = localStorage.getItem("cmpnyNo");
  export const SELECTED_COMPANY_TYPE = localStorage.getItem("cmpnyType");
  //  export const BUILD_POS_TYPE = "RESTAURANT";
  // export const BUILD_POS_TYPE = "ORDER_MANAGEMENT";
   export const BUILD_POS_TYPE = "POS";
   //export const BUILD_POS_TYPE = "PRODUCTION";
