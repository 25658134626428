import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import {
  Grid, TextField,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button, InputLabel
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  Line,
  Area,
  PieChart,
  Pie, Tooltip,
  Cell, LineChart,
  YAxis,
  XAxis, CartesianGrid
} from "recharts";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import noti from '../../images/hrx.png'
import axios from "axios";
// styles
import useStyles from "./styles";
// Utiles
import PageTitle from "../../Utiles/PageTitle/PageTitle";
import Searchbar from "../../Utiles/Searchbar/SearchBar";
import TotalBilImg from '../../images/pos/totalBillImg.png';
import SalesAnalysisImg from '../../images/pos/SalesAnalysis.png';
import SaleItemImg from '../../images/pos/SaleItem.png';
import Alertmessage from "../../Utiles/Alert/Alertmessage";
import themes from "../../themes";
import { GET_ITEM, GET_MASTER_FROM_ITEM, GET_QOH_ITEM, GET_TOTAL_ITEM_PRODUCED } from "../../constant";
import { COMPANY_CODE, GET_ALL_FG_STOCK, GET_ALL_ITEM_PRODUCED, GET_ITEMS_BELOW_ROL, GET_SALE_ORDER_GRID, GET_TOTAL_BILL_OF_DAY, GET_TOTAL_FG_STOCK, GET_TOTAL_FG_STOCK_CATEGORY, GET_TOTAL_ITEM_PRODUCED_CATEGORY } from "../../constant";

const mainChartData = getMainChartData();
const PieChartData = [
  { name: "Group A", value: 400, color: "primary" },
  { name: "Group B", value: 300, color: "secondary" },
  { name: "Group C", value: 300, color: "warning" },
  { name: "Group D", value: 200, color: "success" },
];
const color = [
  "#3DDEFA", "#34C2FF", "#4986FF", "#2055BD"
]



const data = [
  {
    "name": "Page A",
    "uv": 4000,
    "pv": 2400,
    "amt": 2400
  },
  {
    "name": "Page B",
    "uv": 3000,
    "pv": 1398,
    "amt": 2210
  },
  {
    "name": "Page C",
    "uv": 2000,
    "pv": 9800,
    "amt": 2290
  },
  {
    "name": "Page D",
    "uv": 2780,
    "pv": 3908,
    "amt": 2000
  },
  {
    "name": "Page E",
    "uv": 1890,
    "pv": 4800,
    "amt": 2181
  },
  {
    "name": "Page F",
    "uv": 2390,
    "pv": 3800,
    "amt": 2500
  },
  {
    "name": "Page G",
    "uv": 3490,
    "pv": 4300,
    "amt": 2100
  }
]

export default function ItemDashBoard(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [startdate, setstartdate] = useState("")


  const [enddate, setenddate] = useState("")
  const [todaysdate, settodaysdate] = useState("")
  // const [selectedOption, setSelectedOption] = useState('');
  // local
 
  
  const [mainChartState, setMainChartState] = useState("monthly");
  const [ItemsBelowRol, setItemsBelowRol] = useState([]);
  const [shouldUpdate, setShouldUpdate] = useState(true);
  const [loginname, setloginname] = useState(localStorage.getItem('username'))
  const [SaleOrderGRid, setSaleOrderGRid] = useState([])
  const [currentLiveEnquiris, setCurrentLiveEnquiris] = useState(0);
  const [totalbillCountofday, settotalbillCountofday] = useState(1)
  const [CurrentLiveEnquirispopupdata, setCurrentLiveEnquirispopupdata] = useState([])
  const [pendingRFQspopupdata, setpendingRFQspopupdata] = useState([])
  const [Pendingpopopupdata, setPendingpopopupdata] = useState([])
  const [debitorbalancedata, setdebitorbalancedata] = useState([])
  const [creditorbalancedata, setcreditorbalancedata] = useState([])
  const [pendingRFQ, setPendingRFQ] = useState(0);
  const [topClientOrderCount, setTopClientOrderCount] = useState(0);
  const [TopClientOrdername, setTopClientOrdername] = useState("")
  const [topVendorCount, setTopVendorCount] = useState(0);
  const [TopVendorname, setTopVendorname] = useState("")
  const [topCilentCount, setTopCilentCount] = useState(0);
  const [TopCilentname, setTopCilentname] = useState("")
  const [graphDate, setGraphDate] = useState([]);
  const [popupHeader, setpopupHeader] = useState("")
  const [pendingreqpopup, setpendingreqpopup] = useState(false)
  const [currentlivepopup, setcurrentlivepopup] = useState(false)
  const [pendingPOpopup, setpendingPOpopup] = useState(false)
  const [dibitorbalancepopup, setdibitorbalancepopup] = useState(false)
  const [crediorbalancepopup, setcrediorbalancepopup] = useState(false)
  const [SelectedRows, setSelectedRows] = useState([])
  const [isform, setisform] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  const [companyColor, setcompanyColor] = useState("")
  const [popupclass, setpopupclass] = useState("display-none")
  //  FG STOCK
  const [FGstockcount, setFGstockcount] = useState(0)
  const [FGstockClick, setFGstockClick] = useState(false)
  const [FGstockClickData, setFGstockClickData] = useState([])
  const [FGstockClickcellData, setFGstockClickcellData] = useState([])
  const [FGtblcellClick, setFGtblcellClick] = useState(false)

  const [thisBCD, setthisBCD] = useState("")
const [ warehouse,setwarehouse]= useState([])
const [masterfromitem, setmasterfromitem] = useState();
// const [getqohitem, setgetqohitem]= useState();

  // ITEM PRODUCED

  const [ItemProducedcount, setItemProducedcount] = useState(0)
  const [ItemProducedClick, setItemProducedClick] = useState(false)
  const [ItemProducedClickData, setItemProducedClickData] = useState([])
  const [ItemProducedClickcellData, setItemProducedClickcellData] = useState([])
  const [IPtblcellClick, setIPtblcellClick] = useState(false)

  const currentliveenquryclm = [
    { field: 'topClientName', headerName: 'CLIENT NAME', width: 240 },
    { field: 'EnquirisOrderNumber', headerName: 'FINENTIAL YEAR', width: 170 },
    { field: 'RequestNO', headerName: 'REQUEST NO', width: 150 }

  ]
  //total fg stock 
  const FGstockClickDataCLM = [
    { field: 'Item', headerName: 'ITEM', width: 100 },
    { field: 'Qty', headerName: 'QTY', width: 50 },
    { field: 'BCD', headerName: 'BCD', width: 100 },
  ]
  const FGstockCellClickDataCLM = [
    { field: 'Item', headerName: 'ITEM', width: 200 },
    { field: 'Qty', headerName: 'QTY', width: 50 },
  ]
const SaleOrderGRidCLM =[
  { field: 'Order No', headerName: 'Order No', width: 200 },
  { field: 'Date', headerName: 'Date', width: 200 },
  { field: 'Item', headerName: 'Item', width: 300 },
  { field: 'Qty', headerName: 'QTy', width: 100 },
  {field: 'Bal Qty', headdrName:'Bal Qty', width:100},
]

  // total items produced 

  const ItemProducedClickDataCLM = [
    { field: 'Item', headerName: 'ITEM', width: 200 },
    { field: 'Qty', headerName: 'QTY', width: 100 },
    { field: 'BCD', headerName: 'BCD', width: 150 },
  ]
  const ItemProducedCellClickDataCLM = [
    { field: 'Item', headerName: 'ITEM', width: 200 },
    { field: 'Qty', headerName: 'QTY', width: 200 },
  ]

  const debitorbalancedataclm = [
    { field: 'MCODE', headerName: 'ACCOUNT CODE', width: 150 },
    { field: 'topClientName', headerName: 'ACCOUNT NAME', width: 150 },
    { field: 'Debit', headerName: 'DEBITED', width: 80 },
    { field: 'credit', headerName: 'CREDIT', width: 80 },
    { field: 'balance', headerName: 'BALANCE', width: 100 },
  ]

  
  useEffect(() => {
    setcompanyColor(localStorage.getItem('cmpnyType').split(",")[0])
    var a = window.location.href;
  })

   /** ITEM DASHBOARD dropdown */
   axios.post(GET_ITEM,
    { "CompanyNumber": 10 , "Category":""})
    .then((response) => {
      console.log(" Items ")
      console.log(response)
      if (response.data.IsSuccess === true) {
        console.log(response.data.Data);
        setwarehouse(response.data.Data);

      }
    })

    //  ---------- GET MASTER FROM ITEM----------------

    axios.post(GET_MASTER_FROM_ITEM,
       { "CompanyNumber": COMPANY_CODE, "ICODE":"1141"})
      .then((response) => {
        console.log(response)
        if (response.data.IsSuccess === true) {
          console.log(response.data.PurchaseValue);
          console.log(response.data.SellingValue);
          console.log(response.data.MargingValue);
          setmasterfromitem(response.data.data)

        }
      })

   //  ---------- GET QOH ITEM----------------

  //  axios.post(GET_QOH_ITEM,
  //   { "CompanyNumber": COMPANY_CODE, "ICODE":"1119"})
  //  .then((response) => {
  //    console.log(response)
  //    if (response.data.IsSuccess === true) {
  //      setgetqohitem(response.data.data)

  //    }
  //  })

  const getreports = (start, end) => {
    console.log(startdate)
    console.log(enddate)

    //  ---------- GET MASTER FROM ITEM----------------

    // axios.post(GET_MASTER_FROM_ITEM, { "CompanyNumber": COMPANY_CODE, })
    //   .then((response) => {
    //     console.log(response)
    //     if (response.data.IsSuccess === true) {
    //       console.log(response.data.PurchaseValue);
    //       console.log(response.data.SellingValue);
    //       console.log(response.data.MargingValue);
    //       setmasterfromitem(response.data)

    //     }
    //   })
  }

  const getreportseightmonths = () => {
    const today = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 8);

    const options = { month: '2-digit', day: '2-digit', year: 'numeric', };
    const formattedDate = threeMonthsAgo.toLocaleDateString('en-US', options)
      .replace(/\//g, '-');

    console.log(formattedDate);
    var datebeforenineMOnth = formattedDate;

   

  }

  const getreportssixmonths = () => {
    const today = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 6);

    const options = { month: '2-digit', day: '2-digit', year: 'numeric', };
    const formattedDate = threeMonthsAgo.toLocaleDateString('en-US', options)
      .replace(/\//g, '-');


    var datebeforesixMOnth = formattedDate;

   
  }

  const getreportsthreemonths = () => {
    const today = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 3);

    const options = { month: '2-digit', day: '2-digit', year: 'numeric', };
    const formattedDate = threeMonthsAgo.toLocaleDateString('en-US', options)
      .replace(/\//g, '-');

    var datebeforethreeMOnth = formattedDate;

   
  }

  const HandleFGStock = () => {
    setFGstockClick(true)

  }
  const handleCloseFGstockClick = () => {
    setFGstockClick(false)
    setFGtblcellClick(false)
  }

  const HandleItemProduced = () => {
    setItemProducedClick(true)

  }
  const handleCloseItemProducedClick = () => {
    setItemProducedClick(false)
    setIPtblcellClick(false)
  }

  const openRFQsForm = (a, b, c, d) => {
    // debugger;
    console.log(a)
    console.log(b)
    console.log(c)
    console.log(d)
  }
  const handleDashFGtblcellClick = (params) => {
    console.log(params.row.BCD)
    setthisBCD(params.row.BCD)
    axios.post(GET_ALL_FG_STOCK, { "CompanyNumber": COMPANY_CODE, "Warehouse": "110NOIDA", "BCD": params.row.BCD })
      .then((response) => {
        console.log(response)
        if (response.data.IsSuccess === true) {
          setFGstockClickcellData(response.data.Data)
          setFGtblcellClick(true)
        }
      })
    // setthisBCD(params.row.BCD)
  }

  // item produced 

  const handleDashIPtblcellClick = (params) => {

    console.log(params.row.BCD)
    setthisBCD(params.row.BCD)
    axios.post(GET_ALL_ITEM_PRODUCED, { "CompanyNumber": COMPANY_CODE, "BCD": params.row.BCD })
      .then((response) => {
        console.log(response)
        if (response.data.IsSuccess === true) {
          setItemProducedClickcellData(response.data.Data)
          setIPtblcellClick(true)
        }
      })

  }
 
  var nowexactdate = function getFormettedDate() {
    var datentime = ""
    let today = new Date();

    // Get day, month, and year separately
    let day = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();

    // Create an array of month names
    let monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    // Get the month name from the array
    let monthName = monthNames[month - 1];
    datentime = `${day} ${monthName} ${year}`
    return datentime

  }

  const handleResize = () => {
    setShouldUpdate(false);
    setTimeout(() => {
      setShouldUpdate(true);
    }, 500);
  };


  return (
    <>
      {
        isLoading ? <div className='Commonloader'>
        </div> : null
      }
      <Grid container spacing={5} >
        <Grid item lg={8} md={8} sm={8} xs={12}>
          <Searchbar />
        </Grid>
        <div className="notify">
          <img height="20px" src={noti} />
        </div>
       
        <Grid item lg={12} md={12} sm={12} xs={12} className="pb-0">
          <PageTitle title={`Welcome, ${loginname}`}
            button={<Button
              variant="contained"
              size="medium"
              className={classes.pageheading }
              style={{backgroundColor:'#e0e0e000'}}
            >
              <span className={`  ${classes[companyColor]}`}  >{nowexactdate()}</span>
            </Button>} />

            
            <select className="dashboarddropdown">
            <option value="all">ALL</option>
            <option value="category1">Category 1</option>
            <option value="category2">Category 2</option>
            <option value="category3">Category 3</option>

      </select>
        
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className="pt-0" >
          Date Range
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12} className="py-1">
          {/* <InputLabel id="demo-controlled-open-select-label">From Date</InputLabel> */}
          <Grid container spacing={5}>
            <Grid item lg={4} md={4} sm={4} xs={4} className='py-0'>
              <TextField id="standard-basic" type="date" value={startdate}
                onChange={(e) => setstartdate(e.target.value)} />
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={4} className='py-0'>
              <TextField id="standard-basic" type="date" value={enddate}
                onChange={(e) => setenddate(e.target.value)} />
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={4} className='py-0 '>
              <button className={`applyclick ${classes.dashSideBtn}  ${classes.Production_primary} ${classes[companyColor + 'BG']}`} onClick={getreports} >
                Apply
              </button>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid  item lg={2} md={2} sm={1} xs={1}className="py-1"></Grid> */}
        <Grid item lg={6} md={6} sm={6} xs={12} className={`py-1 ${classes.dashSideBtnMain}`}>
          <button className={classes.dashSideBtn} onClick={getreportsthreemonths}>
            Last 3 months
          </button>
          <button className={classes.dashSideBtn} onClick={getreportssixmonths}>
            Last 6 months
          </button>
          <button className={classes.dashSideBtn} onClick={getreportseightmonths}>
            Last 8 months
          </button>
        </Grid>

      </Grid>
      <div className={`category_btnGrp ${companyColor.toUpperCase() == "RESTAURANT" ? "restron_brnGrp" : "pos_brnGrp"}`}>
      <div className='categorydashboardbtn' >  
                 <Button variant='contained' className={`${classes[companyColor+'BG']}  my-1`}  style={{width:"33.33%", margin:"3px", color:"#fff"}} >Master </Button>
                <Button variant="contained"  className={`${classes[companyColor+'BG']}  my-1`}style={{width:"33.33%", margin:"3px", color:"#fff"}}  >Item</Button>
                <Button variant="contained"  className={`${classes[companyColor+'BG']}  my-1`} style={{width:"33.33%", margin:"3px", color:"#fff"}}  >Category</Button>
               
                </div> 
      </div>
      <div>
     <center>
      <select className="dashboarddropdown">
{
warehouse.map((item,i)=>(
  <option value="all">{item.itemName}</option>
)) 
}
        
      </select>
      </center>
      <div>
        <h3 style={{textAlign:"right"}}>Total QOH:- 7868</h3>
      </div>
      </div>
      <center><h2>VIRGIN</h2></center>
     


      <Grid container spacing={6} className={companyColor}>
        < Grid item lg={4} md={4} sm={4} xs={12} className="pb-0">
          <div className={`card py-1 ${classes.row1Card}`}>
            <div className="card-body pb-4">

             <center><h6 className={`card-title my-0 fw-bold`}>Master</h6></center> 
              <Grid container item alignItems={"center"}>
              <Grid item xs={10}>
                  <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                  </h4>
                  <Grid container spacing={5} className="pt-0" >
                   
                    <Grid item xs={6} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                      {
                      masterfromitem.map((item,i)=>(
                        <div key={i}>
                        <li className="list-group-item">
                          <h6 className={classes.textSecondry}>{item.PurchaseValue}</h6></li>
                        <li className="list-group-item">
                          <h6 className={classes.textSecondry}>{item.SellingValue}</h6></li>
                        <li className="list-group-item">
                          <h6 className={classes.textSecondry}>{item.MargingValue}</h6></li>
                          </div>
                        )) 
                          }
                      </ol>
                    </Grid>
                  </Grid>
                </Grid>
              


                <Grid item xs={4} className="text-end">

                  {/* <span > <img src={SaleItemImg}  className='dashboardcardimage w-75'/></span> */}
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        < Grid item lg={4} md={4} sm={4} xs={12} className="pb-0">
          <div className={`card py-1 ${classes.row1Card}`}>
            <div className="card-body ">
              <center><h6 className={`card-title my-0 fw-bold`}>QOH</h6></center>
              <Grid container item alignItems={"center"}>
                <Grid item xs={10}>
                  <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                  </h4>
                  <Grid container spacing={5} className="pt-0" >
                    <Grid item xs={6} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                      {/* {
                      getqohitem.map((item,i)=>(
                        <div key={i}>
                        <li className="list-group-item">
                          <h6 className={classes.textSecondry}>{}</h6></li>
                        
                          </div>
                        )) 
                          } */}
                      </ol>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        < Grid item lg={4} md={4} sm={4} xs={12} className="pb-0">
          <div className={`card py-1 ${classes.row1Card}`}>
            <div className="card-body pb-0">
             <center> <h6 className={`card-title my-0 fw-bold`}>Last 5 Purchase Price</h6></center>
              <Grid container item alignItems={"center"}>
                <Grid item xs={10}>
                  <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                  </h4>
                  <Grid container spacing={5} className="pt-0" >
                    <Grid item xs={6} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fifth one</h6></li>
                      </ol>
                    </Grid>
                    <Grid item xs={6} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fifth one</h6></li>
                      </ol>
                    </Grid>
                  </Grid>
                </Grid>


                {/* <Grid item xs={4}className="text-end">
              <span >
            <img src={SaleItemImg}  className='dashboardcardimage w-75'/></span>
                </Grid> */}
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12} className="pb-0">
          <div className={`card py-1 ${classes.row1Card}`}>
            <div className="card-body pb-4">
                <center><h6 className={`card-title my-0 fw-bold`}>Sale Analysis</h6></center>
                <Grid container item alignItems={"center"}>
                <Grid item xs={10}>
                  <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                  </h4>
                  <Grid container spacing={5} className="pt-0" >
                    <Grid item xs={6} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fifth one</h6></li>
                      </ol>
                    </Grid>
                    <Grid item xs={6} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fifth one</h6></li>
                      </ol>
                    </Grid>
                  </Grid>
                </Grid> 
                </Grid>
             
            </div>
          </div>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12} className="pb-0">
          <div className={`card py-1 ${classes.row1Card}`}>
            <div className="card-body pb-0">
              <center> <h6 className={`card-title my-0 fw-bold`}>Top 5 Purchase Party</h6></center>
              <Grid container item alignItems={"center"}>
              <Grid item xs={10}>
                  <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                  </h4>
                  <Grid container spacing={5} className="pt-0" >
                    <Grid item xs={6} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fifth one</h6></li>
                      </ol>
                    </Grid>
                    <Grid item xs={6} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fifth one</h6></li>
                      </ol>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        <div className={`categorybtn'  ${companyColor.toUpperCase() == "RESTAURANT" ? "restron_brnGrp" : "pos_brnGrp"}`}style={{width:"100%"}}>
      <div className='categorydashboardbtn' style={{padding:"20px"}}>  
                 <Button variant='contained' className={`${classes[companyColor+'BG']}  my-1`}  style={{width:"33.33%", margin:"3px", color:"#fff"}} >Item Master </Button>
                <Button variant="contained"  className={`${classes[companyColor+'BG']}  my-1`}style={{width:"33.33%", margin:"3px", color:"#fff"}}  >Price Stock Ledger</Button>
                <Button variant="contained"  className={`${classes[companyColor+'BG']}  my-1`} style={{width:"33.33%", margin:"3px", color:"#fff"}}  >Stock Statement</Button>
               
                </div> 
      </div>
      
      </Grid>


      <Grid container spacing={5} className="Graphdata" >


        <Grid item lg={12} md={12} sm={12} xs={12} className="my-2">
          <h6 className={`card-title my-0 fw-bold`}>Sale Graph</h6>
          <div onResize={handleResize}>
            <ResponsiveContainer width="100%" height={250}>
              <AreaChart data={data}
                margin={{ top: 10, right: 0, left: 0, bottom: 0 }}>
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="name" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </Grid>
        <br/>
        <Grid item lg={12} md={12} sm={12} xs={12} className="my-2">
          <h6 className={`card-title my-0 fw-bold`}>Production Summary</h6>
          <div onResize={handleResize}>
            <ResponsiveContainer width="100%" height={250}>
              <AreaChart data={data}
                margin={{ top: 10, right: 0, left: 0, bottom: 0 }}>
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="name" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </Grid>


      
        <div className="d">
        </div>
      </Grid>
      <Grid container spacing={5} className="Graphdata" >
     
        <div className="d">
        </div>
      </Grid>
    </>
  );
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  const array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  const resultArray = [];
  const tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  const desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  const mobile = getRandomData(31, 1500, 7500, 7500, 1500);


  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}