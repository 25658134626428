import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import useStyles  from '../PartyStockLedger/style'
import { DataGrid } from '@mui/x-data-grid';

function Gstr() {
const classes = useStyles();
const [GstrtableData,setGstrtableData] = useState([])
const [isLoading,setisLoading] = useState(false)
const [companyColor,setcompanyColor] = useState("")

useEffect(()=>{
    setcompanyColor(localStorage.getItem('cmpnyType'))
},[])

const GstrtableDataRow =[
    { field: 'Voucher', headerName: 'Taxable Amount', width: 150   },
    { field: 'Nart', headerName: 'CGST Amount', width: 150 },
    { field: 'Dabit', headerName: 'SGST Amount', width: 150 },
    { field: 'Credit', headerName: 'IGST Amount', width: 150 },
    { field: 'Currency', headerName: 'CESS Anount', width: 150 }
]


    return (
        <>
         {
            isLoading?  <div className='Commonloader'>
            </div>:null
          }
        <div className="balacepoupformmain Gstr_main" >
            <div className="balacepoupformleft allfont">
             
                {/* Print Account Code*/}
                <div className='row  gstr_ButtonGroup'>
                    
                    <Button variant="contained"  className={classes[companyColor?.split(',')[0]]} >B2B Supplies(b2b)</Button>
                    <Button variant="contained"  className={classes[companyColor?.split(',')[0]]} >Amendes B2B Supplies(b2ba)</Button>
                    <Button variant="contained"  className={classes[companyColor?.split(',')[0]]} >B2C Large(b2cl)</Button>
                    <Button variant="contained"  className={classes[companyColor?.split(',')[0]]} >Amended B2C Large(b2cla)</Button>
                    <Button variant="contained"  className={classes[companyColor?.split(',')[0]]} >B2C Small(b2cs)</Button>
                    <Button variant="contained"  className={classes[companyColor?.split(',')[0]]} >Amended B2C Small(b2csa)</Button>
                    <Button variant="contained"  className={classes[companyColor?.split(',')[0]]} >Credit / Debit Note(cdnr)</Button>
                    <Button variant="contained"  className={classes[companyColor?.split(',')[0]]} >Amended credit / Debit (cdnra)</Button>
                    <Button variant="contained"  className={classes[companyColor?.split(',')[0]]} >credit / debit For Unregistered Persons(cdnur)</Button>
                    <Button variant="contained"  className={classes[companyColor?.split(',')[0]]} >Amwnded credit / debit For Unregistered Persons (cdnura)</Button>
                    <Button variant="contained"  className={classes[companyColor?.split(',')[0]]} > Exports (exp)</Button>
                    <Button variant="contained"  className={classes[companyColor?.split(',')[0]]} >Amended Exports (expa)</Button>
                    <Button variant="contained"  className={classes[companyColor?.split(',')[0]]} >Tax Liability On Advanced(at)</Button>
                    <Button variant="contained"  className={classes[companyColor?.split(',')[0]]} >Ammended Tax Liability On Advanced(ata)</Button>
                    <Button variant="contained"  className={classes[companyColor?.split(',')[0]]} >Advance Adjustments (atad)</Button>
                    <Button variant="contained"  className={classes[companyColor?.split(',')[0]]} >Ambeded Advance Adjustment (atadga)</Button>
                    <Button variant="contained"  className={classes[companyColor?.split(',')[0]]} >Nill Rated,Exampts & Non-GST Supplies(exemp)</Button>
                    <Button variant="contained"  className={classes[companyColor?.split(',')[0]]} >HSN Summary(hsn)</Button>
                    <Button variant="contained"  className={classes[companyColor?.split(',')[0]]} >List of documents Issued (docs)</Button>
                    
                </div>
           

            </div>
            <div className="balacepoupformright">
                <div className='table animatingBottom' style={{height:"540px",width:"100%"}}>
          <DataGrid
          rows={GstrtableData}
          columns={GstrtableDataRow}
          // loading={loading}
          className={classes[companyColor?.split(',')[0]]} 
          pageSize={5}
        //   getRowId={(row) => row.Voucher}
        //     onSelectionModelChange={(ids) => {
        //       const selectedIDs = new Set(ids);
        //       const selectedRows = tabledata.filter((row) =>
        //         selectedIDs.has(row.Voucher),
             
        //       );
    
        //     }}
            {...GstrtableData}
          />
          </div>
          <div className='row  ' style={{float:'right'}} >
                <div className='col ' >
                 <div className='gstrbutton' style={{float:'left', marginBottom:"10px"}} >  
                <Button variant="contained" className={classes[companyColor?.split(',')[0]]}  >generate GSTR1 Excel File</Button>&nbsp;</div> 
                <Button variant="contained" className={classes[companyColor?.split(',')[0]]}  >Print Information</Button>&nbsp;
                <Button variant="contained" className={classes[companyColor?.split(',')[0]]}  >Exit</Button>

                </div>
            </div>
            </div>
           
           
        </div>
        </>
    )
}

export default Gstr;